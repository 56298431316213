export const useInventoryStore = defineStore('inventory', () => {
    const appConfig = useAppConfig();
    const dealersStore = useDealersStore();
    const filtersStore = useFiltersStore();
    const inventoryApi = new InventoryApi();

    const status = ref<Status>('initial');
    const all = shallowRef<Readonly<InventoryV2SearchVehicle>[]>([]);
    const uniqueNewCars = shallowRef<Readonly<InventoryGroupedModel>[]>([]);

    const getNew = computed(() => {
        return all.value.filter((car) => car.type === 'N');
    });

    const getUsed = computed(() => {
        return all.value.filter((car) => car.type === 'U');
    });

    const getCpo = computed(() => {
        // Remove cpo cars if they are the wrong make
        if (appConfig.searchOptions.cpoFilterByMake) {
            return all.value.filter((car) => {
                return (
                    car.isCertified &&
                    dealersStore.currentDealer.makes.includes(car.make)
                );
            });
        }

        return all.value.filter((car) => car.isCertified);
    });

    // The following computeds are the multiple layers of filtering and sort
    // for the SRP results
    const getVehiclesByType = computed(() => {
        if (filtersStore.getSelectedTypes.length === 0) return all.value;

        let vehicles: InventoryV2SearchVehicle[] = [];
        if (filtersStore.getSelectedTypes.includes('new')) {
            vehicles = vehicles.concat(getNew.value);
        }
        if (filtersStore.getSelectedTypes.includes('used')) {
            // Used list also contains cpo, do not combine them
            return vehicles.concat(getUsed.value);
        }
        if (filtersStore.getSelectedTypes.includes('cpo')) {
            vehicles = vehicles.concat(getCpo.value);
        }
        return vehicles;
    });
    const filteredCars = computed(() => {
        return getVehiclesByType.value.filter(
            (car) =>
                filterSelections(car) &&
                filterTags(car) &&
                filterPrice(car) &&
                filterMileage(car) &&
                filterKeywords(car)
        );
    });
    const sortedCars = computed(() => {
        const copy = [...filteredCars.value];
        return copy.sort((a, b) => {
            let first: number | string;
            let second: number | string;
            let compare: number;
            const { by: key, asc } =
                filtersStore.sort[filtersStore.currentType];
            const collator = new Intl.Collator('en', {
                sensitivity: 'base',
                ignorePunctuation: true,
                numeric: true,
            });

            if (key === 'price') {
                first = normalizePrice(a.msrp, a.finalPrice);
                if (first === 0 && asc) {
                    first = 9999999;
                }
                second = normalizePrice(b.msrp, b.finalPrice);
                if (second === 0 && asc) {
                    second = 9999999;
                }
            } else if (key === 'distance') {
                first =
                    dealersStore.valueDealerDistanceMap.get(a.valueArkona) ?? 0;
                second =
                    dealersStore.valueDealerDistanceMap.get(b.valueArkona) ?? 0;
            } else if (key === 'carGurusDealRatingSort') {
                first = a[key] || 0;
                second = b[key] || 0;
            } else {
                first = a[key];
                second = b[key];
            }
            if (typeof first === 'string' || typeof second === 'string') {
                compare = collator.compare(first.toString(), second.toString());
            } else {
                compare = first - second;
                if (compare === 0) {
                    compare = collator.compare(a.vin, b.vin);
                }
            }
            return asc ? compare : compare * -1;
        });
    });
    const groupedAndOrderedCars = computed(() => {
        // create groupings
        const onsiteWithPhoto: InventoryV2SearchVehicle[] = [];
        const onsiteNoPhoto: InventoryV2SearchVehicle[] = [];

        // create groupings with make
        const onsiteMakeWithPhoto: InventoryV2SearchVehicle[] = [];
        const onsiteMakeNoPhoto: InventoryV2SearchVehicle[] = [];

        // put cars into groups
        sortedCars.value.forEach((car) => {
            const hasPhoto =
                car.imageUrls[0] && !car.imageUrls[0].includes('default');

            if (car.type === 'N') {
                if (hasPhoto) {
                    onsiteWithPhoto.push(car);
                } else {
                    onsiteNoPhoto.push(car);
                }
            } else if (hasPhoto) {
                // sort make to the top if in variables
                if (
                    appConfig.inventory.sortMakeToTop &&
                    car.make === appConfig.inventory.sortMakeToTop
                ) {
                    onsiteMakeWithPhoto.push(car);
                } else {
                    onsiteWithPhoto.push(car);
                }
            } else {
                // sort make to the top if in variables
                if (
                    appConfig.inventory.sortMakeToTop &&
                    car.make === appConfig.inventory.sortMakeToTop
                ) {
                    onsiteMakeNoPhoto.push(car);
                } else {
                    onsiteNoPhoto.push(car);
                }
            }
        });

        // return groups in desired order
        return [
            ...onsiteMakeWithPhoto,
            ...onsiteMakeNoPhoto,
            ...onsiteWithPhoto,
            ...onsiteNoPhoto,
        ];
    });
    const paginatedCars = computed(() => {
        return groupedAndOrderedCars.value.filter(
            (_car, index) => index < filtersStore.showingCount
        );
    });
    const carsWithCards = computed(() => {
        // Do nothing if there are no cards
        if (!appConfig.searchOptions.matchingCards.length) {
            return paginatedCars.value;
        }

        // Get seed positions
        const seedPositions = getSeedPositions(paginatedCars.value.length);

        // If no seed positions, return original array
        if (!seedPositions.length) {
            return paginatedCars.value;
        }

        // Copy original array before changing
        const seededArray: (
            | InventoryV2SearchVehicle
            | { cardIndex: number }
        )[] = [...paginatedCars.value];

        // Create pool of available card indexes
        let availableCardPool = appConfig.searchOptions.matchingCards.map(
            (_, index) => index
        );

        // Add seeds into array
        seedPositions.forEach((pos) => {
            // Get random index from available pool
            const cardIndex = Math.floor(
                Math.random() * availableCardPool.length
            );

            seededArray.splice(pos, 0, {
                cardIndex: availableCardPool[cardIndex],
            });

            // Remove the card index from the available pool
            availableCardPool.splice(cardIndex, 1);

            // Reset the available pool if it is empty
            if (!availableCardPool.length) {
                availableCardPool = appConfig.searchOptions.matchingCards.map(
                    (_, index) => index
                );
            }
        });

        return seededArray;
    });

    const GET_ALL = async () => {
        if (status.value === 'loading') return;

        try {
            status.value = 'loading';

            all.value = await inventoryApi.getInventoryV2(
                appConfig.arkonas.fleet || appConfig.arkonas.site
            );

            filtersStore.GET_FILTER_OPTIONS(all.value);

            status.value = 'loaded';
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            status.value = 'error';
        }
    };

    const GET_UNIQUE_NEW_CARS = async () => {
        try {
            uniqueNewCars.value = await inventoryApi.getNewGroupedModels(
                appConfig.arkonas.fleet || appConfig.arkonas.site
            );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    /**
     * Returns a key used in the counts map
     */
    const GET_COUNT_KEY = (makeModel: InventoryUniqueMakeModel) => {
        return `${makeModel.make}-${makeModel.model}`;
    };

    /**
     * Returns a map of make-model -> number.
     * This also counts submodels.
     */
    const counts = computed(() => {
        const map = new Map<string, number>();

        for (const makeModel of uniqueNewCars.value) {
            for (const vehicle of getNew.value) {
                // Match on exact make and model
                const matchesMakeModel =
                    makeModel.make === vehicle.make &&
                    makeModel.model === vehicle.model;

                // Match on submodel
                const matchesSubModel = makeModel.subModels.includes(
                    vehicle.model
                );

                if (matchesMakeModel || matchesSubModel) {
                    const key = GET_COUNT_KEY(makeModel);
                    map.set(key, (map.get(key) ?? 0) + 1);
                }
            }
        }

        return map;
    });

    /**
     * Returns the count of make+model combination
     */
    const GET_COUNT = (makeModel: InventoryUniqueMakeModel) => {
        const key = GET_COUNT_KEY(makeModel);
        const count = counts.value.get(key);
        return count ? `(${count})` : '(0)';
    };

    return {
        status,
        all,
        uniqueNewCars,
        getNew,
        getUsed,
        getCpo,
        getVehiclesByType,
        filteredCars,
        sortedCars,
        groupedAndOrderedCars,
        paginatedCars,
        carsWithCards,
        GET_ALL,
        GET_UNIQUE_NEW_CARS,
        counts,
        GET_COUNT,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useInventoryStore, import.meta.hot));
}
