export const getSeedPositions = (length: number) => {
    if (length < 4) {
        return [];
    }
    // get first seed position
    let lastPosition = 2;
    const seedPositions = [lastPosition];
    // get remaining seed positions
    while (length + seedPositions.length - lastPosition >= 12) {
        const newPosition = 12 + lastPosition;
        seedPositions.push(newPosition);
        lastPosition = newPosition;
    }
    return seedPositions;
};
